import { Time } from "@angular/common";

export class StudentTest {
    studentTestId: number;
    testTime: Time;
    testTimePeriod: Time;
    testDate: String;
    testName: string;
    testNote: string;

    testId: number = 0;
    topicId: number = 0;
    lessonId: number = 0;
    subjectId: number = 0;
    standardDivisionId: number = 0;
    schoolStandardId: number = 0;
    schoolMediumId: number = 0;
    studentId: number;
    teacherId: number;
    schoolId: number;


    topicName: string;
    lessonName: string;
    subjectName: string;
    teacherName: string;

    schoolName: string;
    marks: number;
}
