import { Injectable, NgZone } from '@angular/core';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ParentService } from '../parent.service';

export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

@Injectable({
  providedIn: 'root',
})

export class AuthService {
  public showLoader: boolean = false;
  userData: any; // Save logged in user data
  constructor(
    private parentService: ParentService,
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    private http: HttpClient,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
 
  }
  
  // login(email: string, password: string, user: string) {
  //   this.showLoader = true;
  
  //   const loginDetails = {
  //     admin: {
  //       endpoint: 'admin/adminLogin',
  //       keys: ['_id', 'admin_email', 'admin_name'],
  //       serviceKey: 'admin',
  //       headerKey: { adminEmail: email, adminPassword: password },
  //       route: '/school/school'
  //     },
  //     teacher: {
  //       endpoint: 'teacher/teacherLogin',
  //       keys: ['teacherId', 'teacherEmail', 'teacherName', 'teacherPhoto'],
  //       serviceKey: 'teacher',
  //       headerKey: { teacherEmail: email, teacherPassword: password }
  //     }
  //   };
  
  //   const loginConfig = loginDetails[user];
  
  //   this.http.post(environment.urls + loginConfig.endpoint, loginConfig.headerKey).subscribe((res: any) => {
  //     if (res.status === true) {
  //       this.showLoader = false;
  //       this.parentService[loginConfig.serviceKey] = res.data;
  //       
  //       const { uid, email, displayName, photoURL } = res.data[0];
  //       this.SetUserData(uid, email, displayName, photoURL);
  //       this.parentService.user_type = user;
  //       localStorage.setItem('user_type', user);
  //       localStorage.setItem('user', JSON.stringify(res.data));
  //     //  this.router.navigate([loginConfig.route]);
  //     } else {
  //       this.showLoader = false;
  //       window.alert(res.message);
  //     }
  //   });
  // }
  
  // SetUserData(uid, email, displayName, photoURL) {
  //   const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${uid}`);
  //   const userData: User = {
  //     uid,
  //     email,
  //     displayName,
  //     photoURL,
  //     emailVerified: email, // Check if this should be a boolean value
  //   };
  //   return userRef.set(userData, { merge: true });
  // }
  
  
  login(email: string, password: string, user: string) {
    if (user == 'admin') {
      var header_a = {
        adminEmail: email,
        adminPassword: password
      }
      this.showLoader = true;
      this.http.post(environment.urls + 'admin/adminLogin', header_a).subscribe((res: any) => {
        if (res.status == true) {
          this.showLoader = false;
          
          this.parentService.admin = res.data;
          
          const { adminId, adminEmail, adminName }  = this.parentService.admin[0];
          this.SetUserData(adminId, adminEmail, adminName, '');

          this.parentService.user_type = user;
          localStorage.setItem('user_type', user);
          localStorage.setItem('user', JSON.stringify(res.data));
            this.router.navigate(['/school/school']);
        } else {
          this.showLoader = false;

          window.alert(res.message);
        }
      })
    } else if (user == 'teacher') {

      var header_t = {
        teacherEmail: email,
        teacherPassword: password
      }
      this.showLoader = true;

      this.http.post(environment.urls + 'teacher/teacherLogin', header_t).subscribe((res: any) => {
        if (res.status == true) {
          this.showLoader = false;
          this.parentService.teacher = res.data;
          const { teacherId, teacherEmail, teacherName, teacherPhoto }  = this.parentService.teacher[0];
          
          this.SetUserData(teacherId, teacherEmail, teacherName, teacherPhoto);
          this.parentService.user_type = user; 
          localStorage.setItem('user_type', user);
          localStorage.setItem('user', JSON.stringify(res.data));
          this.router.navigate(['/teacher/students']);

          var schoolHeader = {
            schoolId: this.parentService.teacher.schoolId
          }
          this.http.post(environment.urls + 'school/getSchoolListById', schoolHeader).subscribe((res: any) => {
            if (res.status == true) {
              this.parentService.school = res.data[0];
          }})
       

        } else {
          this.showLoader = false;

          window.alert(res.message);
        }
      })
    }

  }

  SetUserData( uid, email, displayName, photoURL) {
    
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${uid}`
    );
    const userData: User = {
      uid: uid,
      email: email,
      displayName: displayName,
      photoURL: photoURL,
      emailVerified: email,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }

 
  
 
  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        this.router.navigate(['verify-email-address']);
      });
  }
  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error);
      });
  }
  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null && user.emailVerified !== false ? true : false;
  }
 
 
  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.clear();
      localStorage.removeItem('user');
      localStorage.removeItem('user_type');
      this.showLoader = false;
      this.router.navigate(['/auth/login']);
    });
  }
}