import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },

  {
    path: 'masters',
    loadChildren: () => import('../../components/masters/masters.module').then(m => m.MastersModule)
  }, 
  {
    path: 'test',
    loadChildren: () => import('../../components/test/test.module').then(m => m.TestModule)
  }, 
  {
    path: 'school',
    loadChildren: () => import('../../components/school/school.module').then(m => m.SchoolModule)
  }, 
   {
    path: 'teacher',
    loadChildren: () => import('../../components/teacher/teacher.module').then(m => m.TeacherModule)
  }, 
  {
    path: 'attendance',
    loadChildren: () => import('../../components/attendance/attendance.module').then(m => m.AttendanceModule)
  }, 
    
  {
    path: 'androidapp',
    loadChildren: () => import('../../components/android-app/android-app.module').then(m => m.AndroidAppModule)
  },  
  {
    path: 'notification',
    loadChildren: () => import('../../components/notification/notification.module').then(m => m.NotificationModule)
  },

  {
    path: 'candidate',
    loadChildren: () => import('../../components/candidate/candidate.module').then(m => m.CandidateModule)
  },

  {
    path: 'representative',
    loadChildren: () => import('../../components/representative/representative.module').then(m => m.RepresentativeModule)
  },
  {
    path: 'subscriptionplan',
    loadChildren: () => import('../../components/subscription-plan/subscription-plan.module').then(m => m.SubscriptionPlanModule)
  },
  {
    path: 'center',
    loadChildren: () => import('../../components/center/center.module').then(m => m.CenterModule)
  }, {
    path: 'banner',
    loadChildren: () => import('../../components/banner/banner.module').then(m => m.BannerModule)
  }, 
   {
    path: 'gallery',
    loadChildren: () => import('../../components/gallery/gallery.module').then(m => m.GalleryModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('../../components/feedback/feedback.module').then(m => m.FeedbackModule)
  },

];
