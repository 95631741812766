export class Teacher {
    teacherId: number
    teacherName: string;
    teacherEmail: string;
    teacherContactNo: string;
    teacherPhoto: string = null;
    teacherIdNo: string;
    teacherDOB: string;
    schoolId: number;
    teacherPassword: string;
    role: string;
}
