export class Lesson {
    lessonId: number;
    lessonName: string;
    subjectId: number = 0;
    lessonIsActive: number;
    subjectName: string;
    standardId: number = 0;
    mediumId: number = 0;
    mediumName: string ;
    standardName: string;
}
