import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ParentService } from './parent.service';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;

	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Full screen
	public fullScreen: boolean = false;

	constructor(private router: Router, private parentService: ParentService) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if (evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if (window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}

	ngOnDestroy() {
		// this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}


	MENUITEMS: Menu[] = [

		// dashboard
		// {
		// 	path: '/dashboard/default', title: 'Dashboard', icon: 'home', type: 'link'
		// },

		// masters
		{
			title: 'Masters', icon: 'layers', type: 'sub', badgeType: 'success', badgeValue: '',
			children: [
				{ path: '/masters/medium', title: 'Medium', type: 'link' },
				{ path: '/masters/standard', title: 'Standard', type: 'link' },
				{ path: '/masters/division', title: 'Division', type: 'link' },
				{ path: '/masters/subject', title: 'Subject', type: 'link' },
				{ path: '/masters/lesson', title: 'Lesson', type: 'link' },
				{ path: '/masters/topic', title: 'Topic', type: 'link' },
			]
		},
		{
			title: 'Test', icon: 'layers', type: 'sub', badgeType: 'success', badgeValue: '',
			children: [
				{ path: '/test/test', title: 'Test', type: 'link' },
			]
		},

		// android app
		{
			title: 'School', icon: 'layers', type: 'sub', badgeType: 'success', badgeValue: '',
			children: [
				{ path: '/school/school', title: 'School list', type: 'link' },
			]
		},
		 
		 
	];
	MENUITEMS_T: Menu[] = [

		// dashboard
	
		{
			path: '/teacher/students', title: 'Students', icon: 'home', type: 'link'
		},
		{
			path: '/teacher/assign-test', title: 'Assign Test', icon: 'home', type: 'link'
		},
		{
			path: '/teacher/assigned-test', title: 'Assigned Test', icon: 'home', type: 'link'
		},
		 
		// {
		// 	title: 'Teacher', icon: 'layers', type: 'sub', badgeType: 'success', badgeValue: '',
		// 	children: [
		// 		{ path: '/teacher/students', title: 'Students', type: 'link' },
		// 		{ path: '/teacher/assign-test', title: 'Assign Test', type: 'link' },
		// 		{ path: '/teacher/assigned-test', title: 'Assigned Test', type: 'link' },
		// 	]
		// },
		{
			title: 'Attendance', icon: 'layers', type: 'sub', badgeType: 'success', badgeValue: '',
			children: [
				{ path: '/attendance/attendance-take', title: 'Attendance', type: 'link' },
				{ path: '/attendance/attendance-report', title: 'Attendace report', type: 'link' },
			]
		},
		 
	];


	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	items_t = new BehaviorSubject<Menu[]>(this.MENUITEMS_T);
	//megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
	//levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

}
