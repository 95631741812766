
export class Admin {
    adminId: number;
    adminName: string;
    adminEmail: string;
    adminPassword: string;
}

export class User {
    _id: number;
    type: string;
    email: string;
    password: string;
    new_password: string;
}

export class AttendanceReportFilter {
    schoolMediumId: number = 0
    schoolStandardId: number = 0
    standardDivisionId: number = 0
    subjectId: number = 0
    date_from: Date
    date_to: Date
}

export class File {
    path: string;
    id: number = 0;
  };

export class Candidate {

    _id: number;
    password: string = '';
    email: string;
    user_name: string;
    is_verify: boolean = true;
    verification_details: string;
    name: string;
    gender: string = "Male";
    age: string;
    marital_status_id: number;
    marital_status_name: string;
    height: string;
    weight: string;
    date_of_birth: string;
    complexion_id: number;
    complexion_name: string;
    blood_group_id: number;
    blood_group_name: string;
    handicap: string;
    handicap_info: string;
    glasses: string;

    time_of_birth: string;
    birth_place: string;
    gotra: string;
    devak: string;
    zodiac_id: number;
    zodiac_name: string;
    constellation_id: number;
    constellation_name: string;
    nadi: string;
    mangal: string;

    education_id: number;
    education_name: string;
    occupation: string;
    income: string;

    mother_name: string;
    father_name: string;
    contact_number: string;
    alt_contact_number: string;
    father_occupation: string;
    religion_id: number;
    religion_name: string;
    caste_id: number;
    caste_name: string;
    sub_caste_id: number;
    sub_caste_name: string;
    expectations: string;

    state_id: number;
    state_name: string;
    district_id: number;
    district_name: string;
    tehasil_id: number;
    tehasil_name: string;
    center_id: number;
    center_name: string;
    address: string;

    personal_photo: string;
    form_photo: string;
    adhar_card_photo: string;

    is_inter_cast: boolean = false;
    cast_for_interCast: any;
    is_declaration: boolean = true;
    register_date: Date
    adhar_number: string;
    registered_by: string;
    is_married_now: boolean;
    track_details: string;
}

export class Center {
    _id: number;
    name: string;
    contact: string;
    email: string;
    address: string;
    password: string = '';
    state_id: number;
    state_name: string;
    district_id: number;
    district_name: string;
    tehasil_id: number;
    tehasil_name: string;
    is_active: boolean;
}

export class Representative {
    _id: number;
    name: string;
    email: string;
    contact: string;
    password: string = '';
    center_id: number;
    center_name: string;
    state_id: number;
    state_name: string;
    district_id: number;
    district_name: string;
    tehasil_id: number;
    tehasil_name: string;
    address: string;
    is_active: boolean;
    spn: string;
}

export class BloodGroup {
    _id: number;
    blood_group_name: string;
    is_active: Boolean;
}

export class SubscriptionPlan {
    _id: number;
    name: string;
    details: string;
    price: number;
    candidate_to_view: number;
    validity: number; //in days
    is_active: boolean;
}

export class candidateSubscription {
    _id: number;
    name: string;
    price: number;
    details: string;
    candidate_to_view: number;
    validity: number;
    subscription_date: string;
    subscription_end_date: string;
    is_expired: boolean;
    candidate_id: number;
}

export class Feedback {
    _id: number;
    name: string;
    email: string;
    contact: string;
    feedback: string;
    feedback_date: string;
    feedback_time: string;
    is_active: string;
    photo: string
}

export class Gallery {
    _id: number;
    name: string;
    details: string;
    photo: string;
    is_active: string;
    upload_date: Date;
}
export class MaritalStatus {
    _id: number;
    marital_status_name: string;
    is_active: boolean;
}

export class State {
    _id: number;
    state_name: string;
    is_active: Boolean
}

export class District {
    _id: number;
    district_name: string;
    state_id: number;
    is_active: Boolean;
}

export class Tehasil {
    _id: number;
    tehasil_name: string;
    district_id: number;
    state_id: number;
    is_active: Boolean;
}

export class Zodiac {
    _id: number;
    zodiac_name: string;
    is_active: Boolean;
}

export class Complexion {
    _id: number;
    complexion_name: string;
    is_active: Boolean;
}

export class Constellation {
    _id: number;
    constellation_name: string;
    is_active: Boolean;
}

export class Education {
    _id: number;
    education_name: string;
    is_active: Boolean;
}

export class Religion {
    _id: number;
    religion_name: string;
    is_active: Boolean;
}

export class Caste {
    _id: number;
    caste_name: string;
    is_active: Boolean;
}

export class SubCaste {
    _id: number;
    caste_id: number;
    caste_name: string;
    sub_caste_name: string;
    is_active: Boolean;
}

export class Banner {
    _id: number;
    title: string
    details: string
    banner: string
    upload_date: Date = new Date;
    is_active: boolean
}

export class AppDashboardTab {
    _id: number
    title: string
    serial_number: number
    logo: string
    url: string
    is_subtitle: boolean = false;
    is_show_cand_dash: boolean = true
    is_show_main_dash: boolean = true
    is_active: boolean = true;
}

export class SubTab {
    _id: number
    title: string
    serial_number: number
    logo: string
    url: string= 'tabitemdetails'
    description: string
    tab_id: number
    is_active: boolean = true;
}

export class AppLanguages {
    _id: number
    name: string
    details: string
    is_active: boolean
    key_details: string

}

export class MasterDetails {
    _id: string;
    repr_term_cond: string;
    candidate_term_cond: string;
}

export class Notification{
    _id: string;
    title: string;
    subtitle: string;
    date: Date;
    is_for_candidate: boolean = true;
    is_for_repr: boolean = false;
    to:string;
}