<div class="media profile-media">
  <img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt="">
  <div class="media-body"><span> </span>
    <p class="mb-0 font-roboto">{{parentService.user_type}}   <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <!-- <li><a href="/user/profile"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li> -->
  <!-- <li><a href="email"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li> -->
  <!-- <li><a href="/to-do"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li> -->
  <!-- <li><a href="/faq"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
 <li  *ngIf="parentService.user_type == 'teacher'" (click)="passwordFn(password)"><a><app-feather-icons [icon]="'locked'"></app-feather-icons><span>Password</span></a></li>   
  <li (click)="logoutFunc()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span></li>
</ul>

<ng-template #profile let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">
      <ng-container *ngIf="parentService.center._id">New Center</ng-container>
      <ng-container *ngIf="!parentService.center._id">Update Center</ng-container>
    </h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">

    <form #form="ngForm" class="needs-validation" novalidate [ngClass]="{'was-validated': validate}">

      <div class="form-row">
        <div class="col-md-5 mb-3">
          <label class="form-label" for="validationCustom01">Center Name</label>
          <input class="form-control" id="validationCustom01" type="text"  name="name"
            [(ngModel)]="parentService.center.name" required>
        </div>

        <div class="col-md-3 mb-3">
          <label class="form-label" for="validationCustom01">Contact Number</label>
          <input class="form-control" id="validationCustom01" type="number"   name="contact"
            [(ngModel)]="parentService.center.contact" required>
        </div>

        <div class="col-md-4 mb-3">
          <label class="form-label" for="validationCustom01">Email</label>
          <input class="form-control" id="validationCustom01" type="email" 
            name="email" [(ngModel)]="parentService.center.email">
        </div>

      </div>

    </form>
  </div>
  <div class="modal-footer">

    <button [disabled]="!form.valid" class="btn btn-primary me-1" (click)="save()" (click)="modal.close('Save click')">
      <ng-container *ngIf="parentService.center._id">Update</ng-container>
      <ng-container *ngIf="!parentService.center._id">Submit</ng-container>
    </button>

  </div>
</ng-template>


<ng-template #password let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">
    Change Password 
    </h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">

    <form #form="ngForm" class="needs-validation" novalidate [ngClass]="{'was-validated': validate}">

      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label class="form-label" for="validationCustom01">Email</label>
          <input class="form-control" id="validationCustom01" type="text" readonly name="email"
            [(ngModel)]="user.email" required>
        </div>
        <div class="col-md-12 mb-3">
          <label class="form-label" for="validationCustom01">Current Password</label>
          <input class="form-control" id="validationCustom01" type="text"  name="password"
            [(ngModel)]="user.password" required>
        </div>
        <label style="color: red;" *ngIf="error.is_show">{{error.message}} </label>
        
      
        <div class="col-md-12 mb-3">
          <label class="form-label" for="validationCustom01">New Password</label>
          <input class="form-control" id="validationCustom01" type="text"   name="new_password"
            [(ngModel)]="user.new_password" required>
        </div>
      </div>

    </form>
  </div>
  <div class="modal-footer">
    <button [disabled]="!form.valid" class="btn btn-primary me-1" (click)="ChangePass()">
    Change 
    </button>

  </div>
</ng-template>