import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/firebase/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public show: boolean = false;

  constructor( private router: Router) { }

  ngOnInit() {
  }
  login() {
    this.router.navigate(['/auth/login']);
     // this.authService.SignIn(this.loginForm.value['email'], this.loginForm.value['password']);
  }
  showPassword() {
    this.show = !this.show;
  }

}
