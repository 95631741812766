export class Test {
    testId: number;
    testName: string;
    testIsActive: number;

    topicId: number = 0;
    topicName: string;

    lessonId: number = 0;
    lessonName: string;

    subjectId: number = 0;
    subjectName: string;

    standardId: number = 0;
    standardName: string;

    mediumId: number = 0;
    mediumName: string;

}
