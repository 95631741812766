export class TeacherMSD {
    teachermsdId: number;
    teacherId: number = 0;
    schoolMediumId: number = 0;
    mediumName: string;
    schoolStandardId: number = 0;
    standardName: string;
    standardDivisionId: number = 0;
    divisionName: string;
    schoolId: number;
    subjectId: number = 0;
    subjectName: string;
}
