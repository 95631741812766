 
export class Attendance {
    id: number;
    date: Date;
    schoolId: number;
    schoolName: string;
    schoolMediumId: number;
    schoolStandardId: number;
    subjectId: number;
    subjectName: string;
    standardDivisionId: number;
    students: any;
    fcm_token: string;
}

export class Students {
    id: number;
    name: string;
    roll_no: string;
    is_present: boolean;
}
