import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/shared/model/models';
import { AuthService } from 'src/app/shared/services/firebase/auth.service';
import { ParentService } from 'src/app/shared/services/parent.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  user = new User;
  public userName: string;
  public profileImg: 'assets/images/dashboard/profile.jpg';

  constructor(public authService: AuthService, private http: HttpClient, public parentService: ParentService, private modalService: NgbModal) {


  }
  profile(profile) {
    this.modalService.open(profile, { size: 'lg' });
  }


  url: string;

  passwordFn(password) {
debugger
    this.user.type = localStorage.getItem('user_type')

    if (this.user.type == 'admin') {
      this.url = 'admin';
      this.parentService.admin = JSON.parse(localStorage.getItem('user'))[0];
      this.user.email = this.parentService.admin.adminEmail;
      this.user._id = this.parentService.admin.adminId;

    } else {
      this.parentService.teacher = JSON.parse(localStorage.getItem('user'))[0];
      this.user.email = this.parentService.teacher.teacherEmail;
      this.user._id = this.parentService.teacher.teacherId;

      this.url = 'teacher';
    }
    this.modalService.open(password, { size: 'sm' });
  }

  logoutFunc() {
    this.authService.SignOut();
  }

  error: any = {
    message: '',
    is_show: false
  }
  ChangePass() {
    this.http.post(environment.urls + this.url+'/changePassword', this.user).subscribe((res: any) => {
      if (res.status == true) {
        this.modalService.dismissAll();
        this.error.is_show = false;
        this.user = new User;
      } else {
        this.error.is_show = true;
        this.error.message = res.message
      }
    })
  }
  ngOnInit() {
  }

}
