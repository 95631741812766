export class Topic {
    topicId: number;
    topicName: string;
    topicIsActive: number;
    
    lessonId: number = 0;
    lessonName: string;

    subjectId: number = 0;
    subjectName: string;

    mediumId: number = 0;
    mediumName: string;
    
    standardId: number = 0;
    standardName: string;
}
