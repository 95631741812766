export class Student {
    studentId: number;
    studentName: string;
    studentEmail: string;
    studentPhone: string;
    studentAltPhone: string;
    studentRollNo: string;
    studentAddress: string;
    studentDOB: string ;
    studentPhoto: string = null;
    schoolId: number;
    schoolMediumId: number = 0;
    mediumName: string;
    schoolStandardId: number = 0; 
    standardName: string;
    standardDivisionId: number = 0;
    divisionName: string;
    studentPassword: string;

    masterIds: any;
}
