import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/firebase/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ParentService } from 'src/app/shared/services/parent.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;
  
  enter_email: boolean = true;
  is_otp_sent: boolean = false;
  is_otp_verified: boolean = false;

  constructor(private fb: FormBuilder, public modalService: NgbModal, private parentService: ParentService, private http: HttpClient, private router: Router,public authService: AuthService) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      user: ['teacher',Validators.required]
    });
  }



  ngOnInit() { 
  }

  showPassword() {
    this.show = !this.show;
  }



  sendOTP(){
    this.is_otp_sent = true;
    this.enter_email = false;
  }

  verifyOTP(){
    this.is_otp_verified = true;
    this.is_otp_sent = false;
    
  }

submit(){
  this.is_otp_verified = false;
  this.enter_email = true;
}

  forgetPassword(content){
    this.enter_email = true;
   this. is_otp_sent = false;
    this.is_otp_verified = false;

    this.modalService.open(content, { size: 'lg' });

    this.router.navigateByUrl('/authentication/forgot-password')
  }
  
  login() {
    this.authService.login(this.loginForm.value['email'], this.loginForm.value['password'],this.loginForm.value.user );
  }

}
