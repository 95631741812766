import { Component, PLATFORM_ID, Inject } from '@angular/core';
// import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { ParentService } from './shared/services/parent.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,private http: HttpClient, public parentService: ParentService,
    private loader: LoadingBarService) {
 
     
    if(localStorage.getItem('user_type')){
      parentService.user_type = localStorage.getItem('user_type')
      if(parentService.user_type == "teacher"){
        var res = JSON.parse(localStorage.getItem('user'));
        parentService.teacher = res[0];
        var schoolHeader = {
          schoolId: this.parentService.teacher.schoolId
        }
         
        // this.http.post(environment.urls + 'school/getSchoolListById', schoolHeader).subscribe((res: any) => {
        //   if (res.status == true) {
        //     this.parentService.school = res.data[0];
        // }})
     
      }else{
        var res = JSON.parse(localStorage.getItem('user'));
        parentService.admin = res[0];
      }
    }
  
  }

}
