export class StandardDivision {
    standardDivisionId: number;
    divisionId: number = 0;
    divisionName: string;
    schoolMediumId: number = 0;
    mediumName: string;
    schoolStandardId: number = 0;
    standardName: string;
    schoolId: number;
}
