import { Injectable } from '@angular/core';
import { Admin, BloodGroup, Gallery, Notification, Candidate, Banner, Caste, Center, Complexion, Constellation, District, Education, Feedback, MaritalStatus, Religion, Representative, State, SubscriptionPlan, Tehasil, Zodiac, SubCaste, AppDashboardTab, SubTab, AppLanguages, MasterDetails, File, User, AttendanceReportFilter } from '../model/models';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
 
import { School } from '../models/school';
import { Division } from '../models/masters/division';
import { Lesson } from '../models/masters/lesson';
import { Medium } from '../models/masters/medium';
import { Option } from '../models/masters/option';
import { OptionDescription } from '../models/masters/option-description';
import { Question } from '../models/masters/question';
import { Standard } from '../models/masters/standard';
import { Subject } from '../models/masters/subject';
import { Test } from '../models/masters/test';
import { Topic } from '../models/masters/topic';
import { SchoolMedium } from '../models/school-medium';
import { SchoolStandard } from '../models/school-standard';
import { StandardDivision } from '../models/standard-division';
import { Student } from '../models/student';
import { StudentTest } from '../models/student-test';
import { Teacher } from '../models/teacher';
import { TeacherMSD } from '../models/teacher-msd';
import { Attendance } from '../models/attendance';
 
@Injectable({
  providedIn: 'root'
})
export class ParentService {
api_url = environment.urls

// user: any;
  file = new File;
  attendanceReportFilter = new AttendanceReportFilter;
  admin = new Admin;
user = new User;
user_type: string;
// masters
 division = new Division;
 lesson = new Lesson;
 medium = new Medium;
 question = new Question;
 option = new Option;
 standard = new Standard;
 subject = new Subject;
 topic = new Topic;
 test = new Test;
 optionDescription = new OptionDescription;

// other
school = new School;
schoolMedium = new SchoolMedium;
schoolStandard = new SchoolStandard;
standardDivision = new StandardDivision;
teacher = new Teacher;
teacherMSD = new TeacherMSD;
student = new Student;
studentTest = new StudentTest;
attendance = new Attendance;

  maritalStatus = new MaritalStatus;
  state = new State;
  district = new District;
  tehasil = new Tehasil;
  zodiac = new Zodiac;
  complexion = new Complexion;
  constellation = new Constellation;
  education = new Education;
  religion = new Religion;
  caste = new Caste;
  subCaste = new SubCaste;
  bloodGroup = new BloodGroup;
  candidate = new Candidate;
  representative = new Representative;
  subscriptionPlan = new SubscriptionPlan;
  center = new Center;
  feedback = new Feedback;
  gallery = new Gallery;
  banner = new Banner;
  appDashboardTab = new AppDashboardTab;
  subTab = new SubTab;
  appLanguages = new AppLanguages;
  masterDetails = new MasterDetails;
  notification = new Notification;

  maritalStatusList: any;
  stateList: any;
  districtList: any;
  tehasilList: any;
  zodiacList: any;
  complexionList: any;
  constellationList: any;
  educationList: any;
  religionList: any;
  public casteList: any;
  subCasteList: any;
  bloodGroupList: any;
  candidateList: any;
  representativeList: any;
  subscriptionPlanList: any;
  centerList: any;
  feedbackList: any;
  galleryList: any;
  bannerList: any;
  ageList: any;
  appTabList: any;
  appSubtTabList: any;
  trackDetailsList: any;
  appLanguagesList: any;
  notificationList: any;

  trackDetails: any = {
    user_type_id: 0,
    user_name: '',
    date: new Date,
    description: '',
    note: ''
  };
  constructor(private http: HttpClient) {

    this.ageList = [
      "18", "19", "20",
      "21", "22", "23", "24", "25", "26", "27", "28", "29", "30",
      "31", "32", "33", "34", "35", "36", "37", "38", "39", "40",
      "41", "42", "43", "44", "45", "46", "47", "48", "49", "50",
      "51", "52", "53", "54", "55", "56", "57", "58", "59", "60",
      "61", "62", "63", "64", "65", "66", "67", "68", "69", "70",
      "71", "72", "73", "74", "75", "76", "77", "78", "79", "80",
      "81", "82", "83", "84", "85", "86", "87", "88", "89", "90",
      "91", "92", "93", "94", "95", "96", "97", "98", "99", "100"
    ]
  }

  // roles = supper_admin, admin, center, representative, candidate
  registered_by: any = {
    self: false,
    _id: this.admin.adminId,
    name: this.admin.adminName,
    role_type: 'admin'
  }
 
  imageForUpload: {
    image: File;
    imageType: string;
  }

  _uploadImage(image: any, imageType: any) {
    const formData = new FormData();
    formData.append(imageType, image);
     
    this.http.post(environment.urls + 'common/uploadImage', formData).subscribe((res: any) => {
      if (res.status == true) {
      } else {
      }
    })
  }


  uploadImage(image: any, imageType: any, api: any) {
     
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append(imageType, image);
      this.http.post(`${environment.urls}common/${api}`, formData)
        .subscribe(
          (res: any) => {
            if (res.status == true) {
              resolve(res); // Resolve with the response data
            } else {
              reject(new Error("Upload failed")); // Reject with an error message
            }
          },
          (error) => {
            debugger
            reject(error); // Reject with the error object
          } 
        );
    });
  }
  
  removeFile(path: string) {
    return new Promise((resolve, reject) => {
    
    var body = {
      path: path
    }
      this.http.post(`${environment.urls}common/removeFile`, body)
        .subscribe(
          (res: any) => {
            if (res.status == true) {
              resolve(res); // Resolve with the response data
            } else {
              reject(new Error("Upload failed")); // Reject with an error message
            }
          },
          (error) => {
            reject(error); // Reject with the error object
          }
        );
    });
  }
  



  convertImageForVeiw(image: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        resolve(event.target.result); // Resolve the promise with the data URL
      };

      reader.onerror = (event: any) => {
        reject("File could not be read: " + event.target.error.code); // Reject the promise with an error message
      };

      reader.readAsDataURL(image); // Start reading the image as data URL
    });
  }

}
