<div class="container-fluid p-0"> 
  <div class="row">
    <div class="col-12">     
      <div class="login-card">
        <div>
          <div>
            <a class="logo"  routerLink='/'>
             
             WyzrTech
              <!-- <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage">
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage"> -->
            </a>
          </div>
          <div class="login-main"> 
            <form class="theme-form">
              <h4>Create your account</h4>
              <p>Enter your personal details to create account</p>
              <div class="form-group">
                <label class="col-form-label pt-0">Your Name</label>
                <div class="row">
                  <div class="col-6">
                    <input class="form-control" type="text" required="" placeholder="First name">
                  </div>
                  <div class="col-6">
                    <input class="form-control" type="text" required="" placeholder="Last name">
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Email Address</label>
                <input class="form-control" type="email" required="" placeholder="app@yourmail.com">
              </div>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]" required="" placeholder="*********">
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
              </div>
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox">
                  <label class="text-muted" for="checkbox1">Agree with<a class="ms-2" routerLink='/'>Privacy Policy</a></label>
                </div>
                <button class="btn btn-primary d-block w-100"  (click)="login()" type="button">Create Account</button>
              </div>
              <h6 class="text-muted mt-4 or">Or signup</h6>
              <p class="mt-4 mb-0">Already have an account?<a class="ms-2" (click)="login()">Sign in</a></p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>