<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
          </div>
          <div class="login-main">
            <a class="logo" routerLink='/'>
              <img class="img-fluid for-light" style="height: 100px;" src="../../../assets/logo.png" alt="looginpage">
              <img class="img-fluid for-dark" src="assets/logo.png" alt="looginpage">
            </a>
            <form class="theme-form" [formGroup]="loginForm">
              <h4>Sign in</h4>
              <p>Enter your email & password to login</p>

              <div class="form-group">
                <label class="col-form-label">User</label>

                <select class="form-control" formControlName="user" required>
                  <option value="admin">Admin</option>
                  <option value="teacher"> Teacher</option>
                </select>

              </div>

              <div class="form-group">
                <label class="col-form-label">Email Address</label>
                <input class="form-control" type="email" required="" placeholder="app@yourmail.com"
                  formControlName="email">
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
                  class="text text-danger mt-1">
                  Email is required
                </div>
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
                  class="text text-danger mt-1">
                  Invalid Email
                </div>
              </div>

              <div class="form-group">
                <label class="col-form-label">Password</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required=""
                  placeholder="*********">
                <div class="show-hide" (click)="show =! show" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="show =! show" *ngIf="show"><span class="Hide"></span></div>
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                  class="text text-danger mt-1">
                  Password is required
                </div>
              </div>
              <br>
              <div class="form-group mb-0">
                <button class="btn btn-primary d-block w-100" [class.loader--text]="authService.showLoader"
                  [disabled]="!loginForm.valid || authService.showLoader" (click)="login()" type="button"><span>{{
                    authService.showLoader ? '' : 'Login' }}</span>
                </button>

                <br>
              </div>
              <div style="text-align: center;">
                <!-- <a (click)="forgetPassword(forgottPassword)" class="ms-2">forgot password ?</a> -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<ng-template #forgottPassword let-modal>
  <div class="modal-body">

    <div class="login-card">
      <div>

        <div class="login-main">
          <form class="theme-form">


            <!-- enter email section -->

            <div *ngIf="enter_email">
              <div class="form-group">
                <h6 class="mt-4">Reset Your Password</h6>
                <label class="col-form-label">Enter Your Email Address</label>
                <div class="row">

                  <div class="col-md-12">
                    <input class="form-control mb-1" type="email" placeholder="abcd@gmail.com">
                  </div>
                  <div class="col-12">
                    <button class="btn btn-primary d-block w-100 m-t-10" (click)="sendOTP()">Send OTP</button>
                  </div>
                </div>

              </div>

              <div class="text-center mt-4 mb-4"><span class="reset-password-link">If don't receive OTP?  <a
                    class="btn-link text-danger" routerLink='/'>Resend</a></span></div>
            </div>
            <!-- end -->

            <!-- enter otp section -->
            <div style="text-align: center;" *ngIf="is_otp_sent" class="form-group">
              <h6 class="mt-4">Enter OTP</h6>
              <br>
              <div class="row">
                <div class="col">
                  <input class="form-control text-center opt-text" type="text" value="00" maxlength="2">
                </div>
                <div class="col">
                  <input class="form-control text-center opt-text" type="text" value="00" maxlength="2">
                </div>
                <div class="col">
                  <input class="form-control text-center opt-text" type="text" value="00" maxlength="2">
                </div>
                <br>

                <div class="col-12">
                  <button class="btn btn-primary d-block w-100 m-t-10" (click)="verifyOTP()">Verify OTP</button>
                </div>
              </div>
            </div>
            <!-- end -->

            <!-- create password section -->
            <div *ngIf="is_otp_verified">


              <h6 class="mt-4">Create Your Password</h6>
              <div class="form-group">
                <label class="col-form-label">New Password</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]" required=""
                  placeholder="*********">
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Retype Password</label>
                <input class="form-control" type="password" name="login[password]" required="" placeholder="*********">
              </div>
              <div class="form-group mb-0">

                <button (click)="submit()" class="btn btn-primary d-block w-100">Done</button>
              </div>
              <p class="mt-4 mb-0">Already have an password?<a class="ms-2" (click)="modalService.dismissAll()">Sign
                  in</a></p>

            </div>
          </form>
        </div>
      </div>
    </div>


  </div>
</ng-template>